import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Agent, AllAgent } from 'src/app/config/model/agent.model';
import { GlobalConfig } from 'src/app/config/global.config';
import { AllProfil, Profil } from 'src/app/config/model/profil.model';

type EntityResponseType = HttpResponse<Agent>;
type EntityResponseTypeAll = HttpResponse<AllAgent>;
type ProfilResponseTypeAll = HttpResponse<AllProfil>;
type ProfilResponseType = HttpResponse<Profil>;
type DelteAllResponseType = HttpResponse<AllAgent>;

@Injectable()
export class UserService {

  link = 'agent/';
  reqHeader: any;

  constructor(private httpClient: HttpClient, private gb: GlobalConfig) { }

  getAllUser(): Observable<EntityResponseTypeAll> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + this.link + 'get/all';
    return this.httpClient
        .get<AllAgent>(url, {headers : this.reqHeader, observe: 'response'});
  }

  AddUser(data): Observable<EntityResponseType> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + this.link + 'add';
    return this.httpClient
        .post<Agent>(url, data, {headers : this.reqHeader, observe: 'response'});
  }

  updateUser(id, data): Observable<EntityResponseType> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + this.link + 'update/' + id;
    return this.httpClient
        .patch<Agent>(url, data, {headers : this.reqHeader, observe: 'response'});
  }

  ressetPass(id, data): Observable<EntityResponseType> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + this.link + 'resset/' + id;
    return this.httpClient
        .patch<Agent>(url, data, {headers : this.reqHeader, observe: 'response'});
  }

  updateCompte(data): Observable<EntityResponseType> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + this.link + 'update-compte';
    return this.httpClient
        .patch<Agent>(url, data, {headers : this.reqHeader, observe: 'response'});
  }

  getAllProfil(): Observable<ProfilResponseTypeAll> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + 'profil/get/all';
    return this.httpClient
        .get<AllProfil>(url, {headers : this.reqHeader, observe: 'response'});
  }

  AddProfil(data): Observable<ProfilResponseType> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + 'profil/add';
    return this.httpClient
        .post<Profil>(url, data, {headers : this.reqHeader, observe: 'response'});
  }

  deleteAllData(): Observable<DelteAllResponseType> {
    this.reqHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.gb.token);
    const url = this.gb.MAIN_URL + this.link + 'all/all';
    return this.httpClient
        .delete<AllAgent>(url, {headers : this.reqHeader, observe: 'response'});
  }
}
