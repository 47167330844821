import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';



const routes: Routes = [
  {
    path: 'page',
    loadChildren: () => import('./pages/page.module')
      .then(m => m.PageModule),
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
