import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// tslint:disable-next-line: max-line-length
import { NbThemeModule, NbLayoutModule, NbCardModule, NbInputModule, NbButtonModule, NbToastrModule, NbSelectModule, NbDatepickerModule, NbSpinnerModule, NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AuthComponent } from './auth/auth.component';
import { GlobalConfig } from './config/global.config';
import { AuthService } from './auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './pages/user/services/user.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { RouterModule } from '@angular/router';
registerLocaleData(localeFr, 'fr');

@NgModule({
   declarations: [
      AppComponent,
      AuthComponent,
   ],
   imports: [
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      NbThemeModule.forRoot(),
      NbLayoutModule,
      NbEvaIconsModule,
      NbCardModule,
      NbInputModule,
      NbToastrModule.forRoot(),
      NbDatepickerModule.forRoot(),
      FormsModule,
      ReactiveFormsModule,
      NbButtonModule,
      NbSelectModule,
      NbSpinnerModule,
      NbIconModule
   ],
  providers: [GlobalConfig, AuthService, UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
