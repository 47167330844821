<nb-layout>
      
    <nb-layout-column>
        <nb-card class="the-card">
            <h1 id="title" class="title align-self-center">Authentification</h1>
            <p class="sub-title align-self-center">Centre National de Référence de la Drépanocytose</p> <br>
            <form method="post" (ngSubmit)="login()" [formGroup]="loginForm" class="align-self-center" aria-labelledby="title">
                <div class="form-control-group">
                    <label class="label" for="input-email">Matricule:</label>
                    <input type="text" 
                    nbInput 
                    fullWidth 
                    id="input-email"
                    fieldSize="large"
                    autofocus 
                    placeholder="Votre Matricule" 
                    formControlName="matAgent" />
                </div> <br>

                <div class="form-control-group">
                    <span class="label-with-link">
                        <label class="label label-password col-md-4" for="input-password">Mot de passe:</label>
                        <a class="forgot-password caption-2 col-md-4 offset-4"></a>
                    </span>
                    <input type="password"
                    nbInput 
                    fullWidth 
                    id="input-password"
                    fieldSize="large"
                    placeholder="Votre Mot de Passe" 
                    formControlName="motDePasseAgent" />
                </div> <br>

                <div class="form-control-group accept-group">
                    <!-- <nb-checkbox name="rememberMe">Rester connecté</nb-checkbox> -->
                </div>

                <button nbButton
                type="submit"
                fullWidth
                status="primary"
                size="large"
                [nbSpinner]="loading"
                nbSpinnerStatus="info"
                [disabled]="loginForm.invalid">
                    Se connecter
                </button>
            </form>
        </nb-card>
    </nb-layout-column>
    
</nb-layout>

