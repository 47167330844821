import { Agent } from './model/agent.model';
import { AllPatient, Patient } from './model/patient.model';
import { AllConsultation } from './model/consultation.model';
import { AllExamen } from './model/examen.model';
import { AllDiagnostic } from './model/diagnostic.model';

// import { config } from 'dotenv'

class EtatRDV {
  constructor(
    public EnAttente?: string,
    public Active?: string,
    public Prise?: string,
    public Terminee?: string,
    public Annulee?: string
  ) {}
}
class ProfilAgent {
  constructor(
    public ADMIN?: string,
    public MEDECIN?: string,
    public DIRECTRICE?: string,
    public EPIDEMIO?: string,
    public HOSPITALISATION?: string,
    public LABORATOIRE?: string,
    public SERVICE_SOCIAL?: string,
    public PHARMACIE?: string,
    public ADMISSION?: string
  ) {}
}
class EtatOrdo {
  constructor(public Ouverte?: string, public Fermee?: string) {}
}

class EtatLit {
  constructor(public Libre?: string, public Occupé?: string) {}
}

export class GlobalConfig {
  // public MAIN_URL = "http://localhost:5050/";
  public MAIN_URL = 'https://cnrd-niger.herokuapp.com/';

  public token = '';
  public redirect = false;
  public agent: Agent;
  public connected = false;
  public etatRDV = new EtatRDV(
    'En attente',
    'Active',
    'Prise',
    'Terminee',
    'Annulee'
  );
  public etatLit = new EtatLit('Libre', 'Occupé');
  public etatOrdo = new EtatOrdo('Ouverte', 'Fermee');
  public profilAgent = new ProfilAgent(
    'ADMIN',
    'MEDECIN',
    'DIRECTRICE',
    'EPIDEMIO',
    'HOSPITALISATION',
    'LABORATOIRE',
    'SERVICE SOCIAL',
    'PHARMACIE',
    'ADMISSION'
  );
  public dateCreation: Date;
  public allProfil = [
    this.profilAgent.ADMIN,
    this.profilAgent.ADMISSION,
    this.profilAgent.DIRECTRICE,
    this.profilAgent.EPIDEMIO,
    this.profilAgent.HOSPITALISATION,
    this.profilAgent.LABORATOIRE,
    this.profilAgent.MEDECIN,
    this.profilAgent.PHARMACIE,
    this.profilAgent.SERVICE_SOCIAL,
  ];

  public unRDV: any; // pour assurer transition entre deux page;
  public uneOrdonnance: any;
  public listeConsultation: AllConsultation;
  public listeExamen: AllExamen;
  public unProduit: any;
  public sortieHospiData: any;
  public examAttenteOk = false;
  public listeMort: any[] = [];

  public patient: Patient;
  public allPatient: AllPatient;
  // public hospitalisationsPatient: AllHospitalisation;
  public allPatientExist = false;
  public typeConsultation = ['VISITE', 'SUIVI', 'URGENCE'];
  public listeExam: any[];
  public listeChambre: any[];
  public listeMois = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  public HospiSuccess: boolean;
  public bilanPostSanguin: any;
  // tslint:disable-next-line: max-line-length
  public listeAnnees = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031,
    2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043,
    2044, 2045, 2046, 2047, 2048, 2049, 2050,
  ];

  public typeEdu = [
    { value: 'Individuel', title: 'Individuel' },
    { value: 'En Groupe', title: 'En Groupe' },
  ];

  public indications = [
    { value: 'AVC', title: 'AVC' },
    { value: 'Risque AVC', title: 'Risque AVC' },
    { value: 'STA', title: 'STA' },
    { value: 'DTC', title: 'DTC' },
    { value: 'Hyper alergie', title: 'Hyper alergie' },
    { value: 'Ulcere de jambe', title: 'Ulcere de jambe' },
    { value: 'Vasculopatie cérébrale', title: 'Vasculopatie cérébrale' },
    { value: 'CNO', title: 'CNO' },
    { value: 'CNO osseuse', title: 'CNO osseuse' },
    { value: 'Hyperisplomie', title: 'Hyperisplomie' },
  ];

  public motif = [
    { value: 'pour CVO', title: ' pour CVO' },
    { value: 'pour CVO/Palu', title: ' pour CVO/Palu' },
    { value: 'pour CVO Osseuse', title: ' pour CVO Osseuse' },
    { value: 'pour CVO Abdominale', title: ' pour CVO Abdominale' },
    {
      value: 'pour CVO Mixte (osseuse+Abdo)',
      title: ' pour CVO Mixte (osseuse+Abdo)',
    },
    { value: 'pour Priapisme', title: ' pour Priapisme' },
    {
      value: 'pour Palu ou Accès palustre simple',
      title: ' pour Palu ou Accès palustre simple',
    },
    {
      value: 'pour PGFA (Palu grave forme anémique',
      title: ' pour PGFA (Palu grave forme anémique',
    },
    { value: 'pour anémie Sévère', title: ' pour anémie Sévère' },
    {
      value: 'pour STA (syndrome thoracique aigue)',
      title: ' pour STA (syndrome thoracique aigue)',
    },
    { value: 'pour STA+Anémie', title: ' pour STA+Anémie' },
    { value: 'pour STA+Accès palustre', title: ' pour STA+Accès palustre' },
    {
      value: 'pour Hypersplénisme (Anémie+splénomégalie)',
      title: ' pour Hypersplénisme (Anémie+splénomégalie)',
    },
    { value: 'pour AVC', title: ' pour AVC' },
    { value: 'pour AVC+Anémie', title: ' pour AVC+Anémie' },
    { value: 'pour AVC+Accès palustre', title: ' pour AVC+Accès palustre' },
    { value: 'pour Ostéomyélite', title: ' pour Ostéomyélite' },
    { value: 'pour Ulcère de jambe', title: ' pour Ulcère de jambe' },
    { value: 'pour Hydronéphrose', title: ' pour Hydronéphrose' },
    {
      value: 'pour Autres diagnostics (bronchiolite arthrite, etc...)',
      title: ' pour Autres diagnostics (bronchiolite arthrite, etc...)',
    },
    { value: 'pour Causes inconnues', title: ' pour Causes inconnues' },
  ];
  public CauseTransferts = [
    { value: 'Transfert pour STA', title: 'Transfert pour STA' },
    { value: 'Transfert pour ANEMIE', title: 'Transfert pour ANEMIE' },
    { value: 'Transfert pour AVC', title: 'Transfert pour AVC' },
    {
      value: 'Transfert pour Ostéomyélite',
      title: 'Transfert pour Ostéomyélite',
    },
    {
      value: 'Transfert pour autres causes',
      title: 'Transfert pour autres causes',
    },
  ];

  public typeAntecedant = [
    { value: 'chirurgicaux', title: 'chirurgicaux' },
    { value: 'Familiaux', title: 'Familiaux' },
    { value: 'Age Decouverte Maladie', title: 'Age Decouverte Maladie' },
    { value: 'Consanguinité1', title: 'Consanguinité1' },
    { value: 'Consanguinité2', title: 'Consanguinité2' },
    { value: 'Consanguinité3', title: 'Consanguinité3' },
    { value: 'Développement PsychoMoteu', title: 'hospitalisation' },
    { value: 'Consanguinité3', title: 'Consanguinité3' },
    { value: 'Prenatale', title: 'Prenatale' },
    { value: 'Transfusion Sanguine', title: 'Transfusion Sanguine' },
    { value: 'Autre', title: 'Autre' },
  ];
  public typeAllergie = [
    { value: 'Alimentaire', title: 'Alimentaire' },
    { value: 'Animaux', title: 'Animaux' },
    { value: 'Anaphylaxie (mortel)', title: 'Anaphylaxie (mortel)' },
    { value: 'Au latex', title: 'Au latex' },
    { value: 'Asthme allergique', title: 'Asthme allergique' },
    { value: 'Dermite de contact', title: 'Dermite de contact' },
    { value: 'Médicamenteuse', title: 'Médicamenteuse' },
    { value: 'Moisissures', title: 'Moisissures' },
    { value: 'Rhinique allergique', title: 'Rhinique allergique' },
    { value: 'Autre', title: 'Autre' },
  ];
  public examens = [
    { value: 'Alimentaire', title: 'Alimentaire' },
    { value: 'Animaux', title: 'Animaux' },
    { value: 'Anaphylaxie (mortel)', title: 'Anaphylaxie (mortel)' },
    { value: 'Au latex', title: 'Au latex' },
    { value: 'Asthme allergique', title: 'Asthme allergique' },
    { value: 'Dermite de contact', title: 'Dermite de contact' },
    { value: 'Médicamenteuse', title: 'Médicamenteuse' },
    { value: 'Moisissures', title: 'Moisissures' },
    { value: 'Rhinique allergique', title: 'Rhinique allergique' },
    { value: 'Autre', title: 'Autre' },
  ];
  public traitementDeFonds = [
    { value: 'Acide folique', title: 'Acide folique' },
    { value: 'Penive', title: 'Penive' },
    { value: 'Albendazole', title: 'Albendazole' },
    { value: 'Hydratation 1L', title: 'Hydratation 1L' },
    { value: 'Hydratation 2L', title: 'Hydratation 2L' },
    { value: 'Hydratation 3L', title: 'Hydratation 3L' },
  ];
  public typeVaccination = [
    { value: 'BCG/Polio orale -(PO)0', title: 'BCG/Polio orale -(PO)0' },
    {
      value: 'Pentavalent (DTC + Hib+ Hep B) 1ere dose/Po1',
      title: 'Pentavalent (DTC + Hib+ Hep B) 1ere dose/Po1',
    },
    {
      value: 'Pentavalent (DTC + Hib+ Hep B) 2eme dose/po2',
      title: 'Pentavalent (DTC + Hib+ Hep B) 2eme dose/po2',
    },
    {
      value: 'Pentavalent (DTC + Hib+ Hep B) 2eme dose/po3',
      title: 'Pentavalent (DTC + Hib+ Hep B) 2eme dose/po3',
    },
    { value: 'Pentaxim (DTCP+HIB)', title: 'Pentaxim (DTCP+HIB)' },
    {
      value: 'ACT HIB 5 Haemophilus influenza) 1ere dose',
      title: 'ACT HIB 5 Haemophilus influenza) 1ere dose',
    },
    {
      value: 'ACT HIB 5 Haemophilus influenza) 2eme dose',
      title: 'ACT HIB 5 Haemophilus influenza) 2eme dose',
    },
    {
      value: 'ACT HIB 5 Haemophilus influenza) 3eme dose',
      title: 'ACT HIB 5 Haemophilus influenza) 3eme dose',
    },
    {
      value: 'Euvax B( Hépatite B) 1ere dose',
      title: 'Euvax B( Hépatite B) 1ere dose',
    },
    {
      value: 'Euvax B( Hépatite B) 2ere dose',
      title: 'Euvax B( Hépatite B) 2ere dose',
    },
    {
      value: 'Euvax B( Hépatite B) 3ere dose',
      title: 'Euvax B( Hépatite B) 3ere dose',
    },
    { value: 'Rouvax (rougeole)', title: 'Rouvax (rougeole)' },
    { value: 'Stamaril (fievre jaune)', title: 'Stamaril (fievre jaune)' },
    {
      value: 'ROR (rougeole oreillon ou rubéole)',
      title: 'ROR (rougeole oreillon ou rubéole)',
    },
    { value: 'pneumo 23 ou pneumovax', title: 'pneumo 23 ou pneumovax' },
    { value: 'Typhim vi (fievre jaune)', title: 'Typhim vi (fievre jaune)' },
    {
      value: 'Meningocoque (AC ou AcW135Y)',
      title: 'Meningocoque (AC ou AcW135Y)',
    },
    { value: 'Autre', title: 'Autre' },
  ];
  // public typeVaccination = [
  //     {value: 'Rappel', title: 'Rappel'},
  //     {value: 'Vaccination', title: 'Vaccination'}
  // ];

  public typeDeDrepano = [
    { value: 'Hétérozygote AS', title: 'Hétérozygote AS' },
    { value: 'Hétéro composite SC', title: 'Hétéro composite SC' },
    { value: 'Hétérozygite AC', title: 'Hétérozygite AC' },
    { value: 'Homozygote SS', title: 'Homozygote SS' },
    { value: 'Homozygote CC', title: 'Homozygote CC' },
    { value: 'SB Thalassemique', title: 'SB Thalassemique' },
    { value: 'Alpha Thalassemique', title: 'Alpha Thalassemique' },
    { value: 'SS/PHHF', title: 'SS/PHHF' },
    { value: 'SD', title: 'SD' },
    { value: 'SE', title: 'SE' },
    { value: 'SB°', title: 'SB°' },
    { value: 'S APLPHA +', title: 'S APLPHA +' },
  ];
  public listeRegion = [
    { value: 'Agadez', title: 'Agadez' },
    { value: 'Diffa', title: 'Diffa' },
    { value: 'Dosso', title: 'Dosso' },
    { value: 'Maradi', title: 'Maradi' },
    { value: 'Niamey', title: 'Niamey' },
    { value: 'Tahoua', title: 'Tahoua' },
    { value: 'Tillabéri', title: 'Tillabéri' },
    { value: 'Zinder', title: 'Zinder' },
  ];
  public causeDeMort = [
    { value: 'Anémique', title: 'Anémique' },
    { value: 'Complication', title: 'Complication' },
    { value: 'Autre', title: 'Autre' },
  ];
  public formeGalenique = [
    { value: 'Orales', title: 'Orales' },
    { value: 'Injectables', title: 'Injectables' },
    { value: 'Dermiques', title: 'Dermiques' },
    { value: 'Inhalées', title: 'Inhalées' },
    { value: 'Rectales', title: 'Rectales' },
  ];
  public modeSortieHospi = [
    { value: 'Guéri', title: 'Guéri' },
    { value: 'Décès', title: 'Décès' },
    { value: 'Transfert', title: 'Transfert' },
    { value: 'Evacuation', title: 'Evacuation' },
    { value: 'Evasion', title: 'Evasion' },
  ];
  public listeDiagnostics: AllDiagnostic;

  public listeTransfusion = [
    { value: 'Sang total', title: 'Sang total' },
    {
      value: 'Culo globulaire (Concentré erythrocytarie)',
      title: 'Culo globulaire (Concentré erythrocytarie)',
    },
    { value: 'Concentré plaquetaire', title: 'Concentré plaquetaire' },
    { value: 'Plasma frais congelés', title: 'Plasma frais congelés' },
  ];

  public typeFamille = [
    { value: 'Polygame', title: 'Polygame' },
    { value: 'Non polygame', title: 'Non polygame' },
    { value: 'Orphelin', title: 'Orphelin' },
    { value: 'Autre', title: 'Autre' },
  ];
}
