import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Agent } from '../config/model/agent.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import { GlobalConfig } from '../config/global.config';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  agent: Agent;
  loginForm: FormGroup;
  form: FormGroup;
  loading = false;

  // tslint:disable-next-line: max-line-length
  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router, private toastrService: NbToastrService, public vg: GlobalConfig) { }

  ngOnInit(): void {
    // localStorage.removeItem('login');
    this.loginForm = this.fb.group({
      matAgent: ['', Validators.required],
      motDePasseAgent: ['', Validators.required]
    });

  }

  showToast(position, status: NbComponentStatus, message, titre) {
    this.toastrService.show(message, titre, { position, status });
  }

  login() {
    this.loginForm.markAsDirty();
    this.loading = true;

    // Récupération des données du formulaire
    const theAgent = {
      matAgent: this.loginForm.value.matAgent,
      motDePasseAgent: this.loginForm.value.motDePasseAgent
    };
    this.authService.login(theAgent)
      .subscribe((res) => {
        if (res.body.success) {
          this.vg.agent = res.body;
          localStorage.setItem('login', JSON.stringify(this.vg.agent));
          this.vg.token = res.body.token;
          this.vg.connected = true;
          switch (this.vg.agent.reponse.profilAgent) {
            case this.vg.profilAgent.ADMIN:
              
              break;
            case this.vg.profilAgent.ADMISSION:
              
              break;
            case this.vg.profilAgent.DIRECTRICE:
              
              break;
            case this.vg.profilAgent.EPIDEMIO:
              
              break;
            case this.vg.profilAgent.HOSPITALISATION:
              
              break;
            case this.vg.profilAgent.LABORATOIRE:
              
              break;
            case this.vg.profilAgent.MEDECIN:
              
              break;
            case this.vg.profilAgent.PHARMACIE:
              
              break;
            case this.vg.profilAgent.SERVICE_SOCIAL:
              
              break;
          
            default:
              break;
          }
          this.router.navigate(['/page/home']);
        } else {
          this.loading = false;
          this.vg.connected = false;
          this.showToast('top-right', 'danger', 'Identifiants incorrects', 'Erreur');
        }
      });
  }

}
